<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">
      {{ t("headings.bulletin") }}
    </h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="card mb-4">
      <div
        class="grid grid-cols-1 gap-4 p-4 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3"
      >
        <div>
          <label class="block">{{ t("labels.date") }}</label>
          <span v-if="waiting">
            <skeleton class="h-5 w-full" />
          </span>
          <span v-else>{{ getLocaleDate(locale, bulletin.createdAt) }}</span>
        </div>
      </div>
      <hr class="my-4 border-slate-300" />
      <div class="grid grid-cols-1 gap-x-12 gap-y-4 p-4 md:grid-cols-2">
        <div class="flex flex-col gap-y-4 sm:py-4 xl:justify-center">
          <div>
            <span v-if="waiting">
              <skeleton class="mb-2 h-5 w-full" />
            </span>
            <p v-else class="mb-2 text-lg font-semibold">
              {{ bulletin.topic }}
            </p>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else>{{ bulletin.content }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import Skeleton from "../components/loading/Skeleton.vue";
import { getLocaleDate } from "../utils/dateUtils";

export default {
  components: { Breadcrumb, FormButton, Skeleton },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { locale, t } = useI18n();

    const bulletin = computed(() => store.state.bulletin.bulletin);

    const waiting = computed(() => {
      if (store.state.bulletin.waiting) return true;
      if (store.state.authentication.waiting) return true;
      return false;
    });

    onMounted(() => {
      if (!bulletin.value || bulletin.value.id != route.params.bulletinId) {
        store.dispatch("bulletin/getBulletin", route.params.bulletinId);
      }
    });

    return {
      bulletin,
      getLocaleDate,
      locale,
      router,
      t,
      waiting,
    };
  },
};
</script>
